.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%; /* Full width */
  height: 80px; /* Adjust as needed */
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: space-around; /* Distribute items evenly with space on the ends */
  background-color: #282c34; /* Add a background color */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  color: white;
  position: fixed; /* Make the header fixed */
  top: 0; /* Position it at the top */
  z-index: 1000; /* Ensure it stacks on top */
}

.Header-content {
  display: flex; /* Align items in a row */
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: space-around;
  margin: 0 auto; /* Center the content */
  width: 80%;
}

.App-logo {
  height: 50px; /* Adjust as needed */
  pointer-events: none;
}

.Link-list {
  display: flex; /* Align items in a row */
  flex-direction: row; /* Align items in a row */
  list-style-type: none; /* Remove bullet points */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.Link-list li {
  margin: 0 10px; /* Add some horizontal margin */
}

.App-link {
  color: #61dafb;
  text-decoration: none; /* Remove underline */
  font-size: 1.2em; /* Increase font size */
  transition: color 0.2s ease-in-out; /* Smooth color transition */
}

.App-link:hover {
  color: #fff; /* Change color on hover */
}

.App-main {
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  margin: 80px auto 0px auto; /* Center the div on the page */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.Row-pad-top {
  padding-top: 50px; /* Add padding to the top */
}

.Row-pad-bottom {
  padding-bottom: 50px; /* Add padding to the bottom */
}

.Centered-textbox {
  display: flex; /* Align items in a row */
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  text-align: left;

}

.Profile-image {
  width: 200px;
  height: auto;
}

.Pad-bottom {
  padding-bottom: 40px; /* Add padding to the bottom */
}

.Pad-top {
  padding-top: 40px; /* Add padding to the top */
}

.Section-top {
  padding: 20px; /* Add padding to the top */
  width: 100%; /* Set width to 80% of the page width */
  height: 100%; /* Set height to 100% of the viewport height */
  background-color: rgba(0, 0, 0, .6); /* Set background color to a darker, semi-transparent color */
  color: whitesmoke;
}

.Content{
  display: flex; /* Align items in a row */
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 0 auto; /* Center the content */
  padding: 20px;
}

.Flex-content {
  display: flex; /* Align items in a row */
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 0 auto; /* Center the content */
}

.Section {
  padding: 60px 0 60px 0; /* Add padding */
  width: 100%; /* Set width to 80% of the page width */
  height: 100%; /* Set height to 100% of the viewport height */
  /* color: whitesmoke; */
}

.Dark {
  background-color: rgba(0, 0, 0, .6); 
  color: whitesmoke
}

.Light {
  background-color: rgba(255, 255, 255, .6); 
  color: black
}

.Blue {
  background-color: rgba(0, 0, 255, 0.309); 
  color: whitesmoke
}

.White {
  background-color: rgba(255, 255, 255); 
  color: black
}

.Skill-icon {
  width: 200px;
  height: 200px;
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(170deg) brightness(116%) contrast(101%);
  margin: 20px 0;
}

.Hexagon-border {
  width: 250px; /* Set width */
  transition: .2s ease-in-out; /* Add a smooth transition */
}

.Hexagon-border:hover {
  transform: scale(1.1);
}

.Hexagon-text {
  font-size: 25px;
  color: #ffffff;
  text-shadow: 1px 1px 5px #ffffff;
}

.Skill-title {
  font-size: 1.5em; /* Increase font size */
  color: #61dafb; /* Change text color */
}

.Skill-list {
  display: flex; /* Make the list a flex container */
  flex-direction: column; /* Arrange items in a row */
  list-style-type: none; /* Remove bullet points */
  justify-items: left;
  padding: 0; /* Remove padding */
  justify-content: space-around; /* Distribute items evenly with space around them */
}

.Burnt-orange {
  color: #e86100;
}

.Navy-blue {
  color: #0b2341;
}

.Certificate-image {
  width: 300px;
  height: auto;
  margin: 20px 0;
}

@media screen and (max-width: 1200px) {
  .Skill-list {
    flex-direction: column; /* Arrange items in a column */
    list-style-position: inside;
  }
  
}

.App-footer {
  width: 100%; /* Full width */
  height: 100px; /* Adjust as needed */
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  background-color: #282c34; /* Add a background color */
  color: white;
  position: relative; /* Make the footer fixed */
  bottom: 0; /* Position it at the bottom */
  z-index: 1000; /* Ensure it stacks on top */
  border-top: 5px solid #61dafb; /* Add a border to the top */
}